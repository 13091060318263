import React, {useEffect, useState} from 'react'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import {postCallWithresponse} from "../../../api/apiCall";
import swal from "sweetalert";
import {CONTACT} from "../../../api/apiRoutes";


export default function (){
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
        recaptchaToken: null,
    })
    const verifyCallback = (data) => {
        setFormData({
            ...formData,
            recaptchaToken: data
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await postCallWithresponse(CONTACT,formData)
        if(result?.code === 200){
            setFormData({
                name: '',
                email: '',
                mobile: '',
                subject: '',
                message: '',
                recaptchaToken: null,
            })
            swal('Success:', result?.message, 'success');
        } else if(result?.code === 401){
            swal('Success:', 'Your session expired please reload and submit again', 'error');
        } else {
            swal('Error:', result?.message, 'error');
        }
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        loadReCaptcha('6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH');
    },[])

    return (
        <div>
            <form className="contact-form row" onSubmit={handleSubmit} method='post'>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input id="name" className="form-control form-mane" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required={true} type="text" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                        <label htmlFor="email">E-mail</label>
                        <input id="email" className="form-control form-mane" placeholder="Email" value={formData.email} onChange={handleChange} name="email" required={true} type="email" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                        <label htmlFor="email">Mobile</label>
                        <input id="email" className="form-control form-mane" placeholder="Mobile Number" value={formData.mobile} onChange={handleChange} name="mobile" required={true} type="text" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                        <label htmlFor="subject">Subject</label>
                        <input id="subject" className="form-control form-mane" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required={true} type="text" />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" className="form-control form-message" name="message" cols="10" rows="10" name="message" onChange={handleChange} placeholder="Message" value={formData.message} required={true}></textarea>
                    </div>

                    <ReCaptcha
                        sitekey="6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH"
                        action='homepage'
                        verifyCallback={verifyCallback}
                    />

                    <div className="submit-button">
                        <button className="btn btn-button green-bg button-1"><i className="fa fa-paper-plane" style={{marginRight:'5px'}}></i>Send Mail</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
