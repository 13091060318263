import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import SearchBox from './searchBoxData';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { postCallHeader } from '../../api/apiCall'
import { apiCall } from '../../api/apiCall'
import { GET_LOCATION, MOVIE_SEARCH_LIST } from '../../api/apiRoutes'
import Logo from '../../images/cine_logo2.png'
import LocationModal from '../common/locationModal'
import PurchaseTicket from '../common/purchaseModal'
import {
    isAndroid,
    isIOS
} from 'react-device-detect';
import {store} from "../../redux/store";

function header({locationData,isLoad}) {
    const location = useLocation();
    const [showLocation, setShowLocation] = useState(true)
    const [searchBoxShow, setSearchBoxShow] = useState(false)
    const [movieSearch, setMovieSearch] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [movieList, setMovieList] = useState([])
    const [searchLocation, setSearchLocation] = useState(new URLSearchParams(location.search).get('location'))

    /**
     * @param {string} location
     * @param {string} path
     */
    const menuClassName = (location, path) => {
        let className = "not-active";
        if (location === path) className = "active";
        return className;
    };

    const getLocation = async () => {
        const locationData = await postCallHeader(GET_LOCATION, {})
        await setLocationList(locationData)
        if(searchLocation){
            const result = locationData.filter(location => location.id == searchLocation);
            var location = result.length ? result[0] : locationData[0]
            localStorage.setItem('theater', JSON.stringify(location))
            store.dispatch({
                type:'SET_LOCATION',
                payload:location
            })
        }
        if(!localStorage.getItem('theater')) {
            localStorage.setItem('theater', JSON.stringify(locationData[0]))
            store.dispatch({
                type:'SET_LOCATION',
                payload:locationData[0]
            })
        }
    }

    const handleToggle = () => {
        setMovieSearch(!movieSearch)
        if(movieSearch){
            document.getElementById('movieSearchInput').value = ''
            setSearchBoxShow(false)
        }
    }

    /**
     * @param {{ target: { value: any; }; }} e
     */
    const getData = async (e) => {
        var data = []
        if (e.target.value) {
            data = await apiCall(MOVIE_SEARCH_LIST, { 'q': e.target.value })
        }
        setMovieList(data)
        if (data.length > 0) {
            setSearchBoxShow(true)
        } else {
            setSearchBoxShow(false)
        }
    }

    const enableLoader = () => {
        var $preloader = $('.preloader');
        $preloader.css('display','block')
        $preloader.find('.preloader-lod').css('display','block');
    }

    const hideNavForMobile = () => {
        $('.navbar-collapse').removeClass('in show')
    }

    const hideMovieSearchBox = () => {
        $('.header-search-input').removeClass('search-open').addClass('search-close')
        $('.search-result-box').css('display','none');
    }

    useEffect(() => {
        enableLoader()
        hideNavForMobile()
        hideMovieSearchBox()
        isLoad(false)
        if (['/contact','/about-cineplex','/member','/concession','/career','/gallery','/ticket-price','/student-reward', '/e-card' ].includes(location.pathname)) {
            setShowLocation(false)
            isLoad(true)
        } else {
            getLocation().then(() => {
                isLoad(true)
            })
            setShowLocation(true)
        }
        $(document).ready(() => {
            var $preloader = $('.preloader');
            $preloader.find('.preloader-lod').fadeOut();
            $preloader.delay(350).fadeOut('slow');
        })

    }, [location.pathname, searchLocation])

    return (
        <>
            <div className='preloader'>
                <div className='preloader-lod'></div>
                <div className='preloader-lod'></div>
                <div className='preloader-lod'></div>
                <div className='preloader-loding'>Cineplex…</div>
                <div className='large-square'></div>
            </div>

            <header className="header-section">
                <div className="header-center">
                    <div className="container">
                        <div className="row" style={{ display: 'flex' , alignItems: 'center', justifyContent: 'space-between'}}>
                            <div className="col-lg-6 col-md-6 col-sm-3 col-xs-4 header-logos sm-width">
                                <div className="header-logo">
                                    <Link to="/">
                                        <img src={Logo} alt="logo" />
                                    </Link>
                                </div>
                            </div>
                           
                            <div className="col-lg-6 col-md-6 col-sm-8 col-xs-7 header-search-area sm-width">
                                <div className="header-search categorie-search-box">
                                    <form action="#">
                                        <input className={movieSearch ? "form-control header-search-input search-open" : "form-control header-search-input"} type="text"
                                               placeholder="Search Here" id="movieSearchInput" onKeyUp={getData} />
                                        <button type="button" id="search-toggle" className="blue-bg" onClick={handleToggle}><span
                                            className="fa fa-search"></span>
                                        </button>
                                    </form>
                                </div>
                                {
                                    searchBoxShow ? (<SearchBox list={movieList}/>) : ('')
                                }
                            </div>
                            {/* <div className="col-lg-3 col-md-3 hidden-sm hidden-xs">  
                                {
                                    showLocation ?
                                        <div className="social">
                                            <div className="attr-nav">
                                                <div className="styled-select" id="lang-selector">
                                                    <button className="location_pick_modal" id="location-button-name" type="button" name="button"
                                                            data-toggle="modal" data-target="#loc_cng_modal">
                                                        {
                                                            locationData ?
                                                                locationData?.location_name
                                                                :
                                                                "No Location Selected"
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        : ('')
                                }
                            </div> */}

                            <div className="col-lg-1 col-xs-1 hidden-lg hidden-md" style={{ float: 'right' }}>
                                <div className="navbar-header" style={{ float: 'right', marginRight: 0 }}>
                                    <button type="button" className="navbar-toggle" data-toggle="collapse"
                                            data-target="#navbar-menu" style={{ marginRight: 0 }}>
                                        <i className="fa"></i>
                                    </button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div className="wrap-sticky" style={{ "height": "34px" }}>
                    <nav className="navbar navbar-default bootsnav navbar-sticky ">
                        <div className="container"> 
                            {
                                showLocation ?
                                    <div className="social">
                                        <div className="attr-nav">
                                            <div className="styled-select" id="lang-selector">
                                                <button className="location_pick_modal" id="location-button-name" type="button" name="button"
                                                        data-toggle="modal" data-target="#loc_cng_modal">
                                                    {
                                                        locationData ?
                                                            locationData?.location_name
                                                            :
                                                            "No Location Selected"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    : ('')
                            }  
                            
                            <div className="collapse navbar-collapse" id="navbar-menu">
                                <ul className="nav navbar-nav navbar-left" data-in="" data-out="">
                                    <li className={menuClassName(location.pathname, "/")}><Link to="/">Home</Link></li>
                                    <li className={menuClassName(location.pathname, "/show-time")}><Link to="/show-time">Showtimes</Link></li>
                                    <li className={menuClassName(location.pathname, "/about-cineplex")}><Link to="/about-cineplex">About Us</Link></li>
                                    {/*<li className={menuClassName(location.pathname, "/member")}><Link to="/member">Member</Link></li>*/}
                                    <li className={menuClassName(location.pathname, "/concession")}><Link to="/concession">Concession</Link></li>
                                    <li className={menuClassName(location.pathname, "/career")}><Link to="/career">Career</Link></li>
                                    <li className={menuClassName(location.pathname, "/ticket-price")}><Link to="/ticket-price">Ticket Price</Link></li>
                                    {/*<li className={menuClassName(location.pathname, "/student-reward")}><Link to="/student-reward">Student Reward</Link></li>*/}
                                    <li className={menuClassName(location.pathname, "/contact")}><Link to="/contact">Contacts</Link></li>
                                    {/*<li><a href={isIOS ? 'https://apps.apple.com/us/app/star-cineplex/id1502393817' : 'https://play.google.com/store/apps/details?id=com.cineplexbd.starcineplex&hl=en'} target='_blank'>Download App</a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

            </header>

            <LocationModal hall={locationList} />

            <PurchaseTicket />
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        locationData: state.location,
    }
}

export default connect(mapStateToProps)(header);
